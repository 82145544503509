<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            :disabled="$auth.disabled(method)"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-6"
            label="ราคา/หน่วย"
            placeholder="100 3%..."
            v-model="formData.discountPrice">
          </sgv-input-text>

          <DocConfigFormUnit
            label="หน่วย"
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            class="col-6"
            select="id"
            v-model="formData.unitId"
            :validations="[
              {text: 'required!', value: $v.formData.unitId.$dirty && !$v.formData.unitId.required}
            ]">
          </DocConfigFormUnit>
        </div>

        <div class="form-row">
          <DocConfigFormAccount
            label="บัญชี"
            :disabled="$auth.disabled(method)"
            :docType="docType"
            :templateType="templateType"
            class="col-12"
            select="id"
            v-model="formData.accountId"
            :validations="[
              {text: 'required!', value: $v.formData.accountId.$dirty && !$v.formData.accountId.required}
            ]">
          </DocConfigFormAccount>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              label="ค่าเริ่มต้น"
              inline
              v-model="formData.isDefault">
            </sgv-input-check>
          </div>
        </div>
      </sgv-form>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'
import DocConfigFormUnit from '@/views/doc_core/components/DocConfigFormUnit'
import {
  DETAIL_DISCOUNT_PRICE,
  CREATE_DISCOUNT_PRICE,
  UPDATE_DISCOUNT_PRICE,
  DESTROY_DISCOUNT_PRICE,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        name: null,
        unitId: null,
        accountId: null,
        discountPrice: '',
        isDefault: false
      },
      isLoaded: false,
    }
  },
  validations: {
    formData: {
      name: { required },
      unitId: { required },
      accountId: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.discountPrice)})
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = []

      removeKeys.forEach(x => delete input[x])

      return input
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.discountPrice.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DocConfigFormAccount,
    DocConfigFormUnit,
  }
}
</script>

<style lang="css">
</style>
